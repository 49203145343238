/* You can add global styles to this file, and also import other style files */
* {
    box-sizing: content-box;
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

/* Webkit (Chrome, Safari, Edge) */
::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color del thumb (la barra que puedes arrastrar) */
    border-radius: 2px;
}

::-webkit-scrollbar-track {
    background-color: #EEF5FF;
    /* Color de fondo del track (la parte de la barra que no está ocupada por el thumb) */
}

/* Para quitar flechas del typer number */
/* Para Chrome, Safari, Edge y Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Para Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

/* PrimeNG */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import '~primeflex/primeflex.css';
